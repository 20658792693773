<template>
  <video id="video-player" controls></video>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'AWSStream',
  props: ['streamUrl'],
  data() {
    return {
      drag: false,
    };
  },
  created() {
    const $script = document.createElement('script');
    $script.onload = () => {
      // eslint-disable-next-line no-undef
      if (IVSPlayer && IVSPlayer.isPlayerSupported) {
        // eslint-disable-next-line no-undef
        const player = IVSPlayer.create();
        const $player = document.getElementById('video-player');
        if ($player) {
          player.attachHTMLVideoElement($player);
          player.load(this.streamUrl);
          player.play();
        }
      }
    };
    $script.src = 'https://player.live-video.net/1.5.0/amazon-ivs-player.min.js';
    document.body.appendChild($script);
  },
};
</script>
<style lang="scss"></style>
