








































































































// @ is an alias to /src
/* eslint-disable no-underscore-dangle */

import { MutationPayload } from 'vuex';
import Chat from '@/components/chat.vue';
import { connectToRocketChat, getChannelMessages, joinRocketChannel } from '@/api/chat';
import { IRocketChatStreamMessage, IVueChatMessage } from '@/models/chat';
import store from '@/store';
import { ISession } from '@/models/session';
import { getSessionById, getSessionChat } from '@/api/sessions';
import AWSStream from '@/components/aws-stream.vue';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Chat,
    AWSStream,
  },
  data() {
    return {
      event: 0,
      sessionId: 0,
      socketInit: false,
      rc: connectToRocketChat(),
      chat: {
        userId: '',
        roomId: '',
        roomName: 'Chat',
        messages: [] as IVueChatMessage[],
        theme: 'dark',
      },
      session: {} as ISession,
      chatEnabled: false,
    };
  },
  computed: {
    embedUrl(): string {
      let sessionUrl = this.session.external_link;

      if (!sessionUrl || sessionUrl === '') {
        sessionUrl = `https://meet.beamian.com/${this.sessionId}`;
      }

      return sessionUrl;
    },
  },
  setup() {
    const { translations } = useContext();
    return { translations };
  },
  created() {
    this.rc = connectToRocketChat();
    this.sessionId = parseInt(this.$route.params.session, 10);
    this.event = parseInt(this.$route.params.event, 10);
    // ping server to keepAlive
    this.rc.connectToServer().subscribe(
      () => {
        this.rc.keepAlive().subscribe(); // Ping Server
      },
      (error) => {
        console.log('ping server error', error);
      },
    );

    getSessionById(this.sessionId).then((response) => {
      if (response.data) {
        this.session = response.data;
        const rocketAuth = store.getters.getRocketAuth;

        if (rocketAuth.rocket_chat_auth_token) {
          this.chat.userId = rocketAuth.rocket_chat_user_id;
          this.loginAndConnect(rocketAuth.rocket_chat_auth_token);
        }
      }
    });

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setRocketUserLogin') {
        const rocketAuth = store.getters.getRocketAuth;

        if (rocketAuth.rocket_chat_auth_token) {
          this.chat.userId = rocketAuth.rocket_chat_user_id;
          this.loginAndConnect(rocketAuth.rocket_chat_auth_token);
        }
      }
    });
  },
  methods: {
    joinChannelAndStream(channel: string) {
      joinRocketChannel({ roomId: channel, joinCode: '' }).then((response) => {
        console.log(response.data);
        this.rc.sendMessage({
          msg: 'sub',
          id: `${new Date().getTime()}`,
          name: 'stream-room-messages',
          params: [channel, false],
        });
      });
    },
    getChannelMessages(channel: string) {
      getChannelMessages(channel).then((response) => {
        const { messages } = response.data;
        const language = store.getters.getLanguage;
        if (messages) {
          this.chat = {
            ...this.chat,
            messages: [
              ...messages
                .sort((prev, next) => {
                  if (prev._updatedAt < next._updatedAt) {
                    return -1;
                  }
                  return 1;
                })
                // filter messages about users joining the channel (t property unique to those cases)
                .filter((msg) => !msg.t)
                .map((msg) => {
                  const date = new Date(msg._updatedAt as string);
                  return {
                    _id: msg._id,
                    content: msg.msg,
                    sender_id: msg.u._id,
                    username: msg.u.name,
                    date: date.toLocaleDateString(language),
                    timestamp: date.toLocaleTimeString(language),
                    system: false,
                    saved: true,
                    distributed: true,
                    seen: true,
                    disable_actions: true,
                    disable_reactions: true,
                  };
                }),
            ],
          };
        }
      });
    },
    loginAndConnect(token: string) {
      getSessionChat(this.sessionId).then((response) => {
        if (response.data.results.length > 0) {
          this.rc.loginWithAuthToken(token).subscribe(
            (apiEvent) => {
              if (apiEvent.msg === 'result' && !this.socketInit) {
                this.initSocket();
                this.socketInit = true;
                const roomId = response.data.results[0].rocket_chat_room_id;
                this.chatEnabled = true;
                this.chat.roomId = roomId;
                this.getChannelMessages(roomId);
                this.joinChannelAndStream(roomId);
              }
            },
            (error) => {
              console.log(error);
            },
          );
        }
      });
    },

    initSocket() {
      this.rc.onError((error) => console.log(error));
      this.rc.onCompletion(() => console.log('finished'));
      this.rc.onMessage((message) => {
        const msg = message as IRocketChatStreamMessage;
        if (msg.fields && msg.fields.args && msg.fields.args[0]) {
          const date = new Date(msg.fields.args[0]._updatedAt.$date);
          const message = msg.fields.args[0];
          if (!message.t) {
            const language = store.getters.getLanguage;

            this.chat.messages = [
              ...this.chat.messages,
              ...[
                {
                  _id: msg.id,
                  content: msg.fields.args[0].msg,
                  sender_id: msg.fields.args[0].u._id,
                  username: msg.fields.args[0].u.name,
                  date: date.toLocaleDateString(language),
                  timestamp: date.toLocaleTimeString(language),
                  system: false,
                  saved: true,
                  distributed: true,
                  seen: false,
                  disable_actions: true,
                  disable_reactions: true,
                },
              ],
            ];
          }
        }
      });
    },

    sendMessage(msg: { content: string; file: File | null; replyMessage: string; roomId: number }) {
      this.rc.sendMessage({
        msg: 'method',
        method: 'sendMessage',
        id: `${new Date().getTime()}`,
        params: [
          {
            _id: `${new Date().getTime()}`,
            rid: msg.roomId,
            msg: msg.content,
          },
        ],
      });
    },
  },
});
